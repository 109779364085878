
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject } from 'vue';
import { Input, Select } from '@/components/input-elements';
import { Apollo, Notify } from '@/core/services';
import { GET_DIMENSIONS } from '@/modules/catalog/dimensions/graphql/Queries';
import { UPDATE_DIMENSION } from '@/modules/catalog/dimensions/graphql/Mutations';
import { EditDimensionForm } from '@/modules/catalog/dimensions/interfaces';
import { useI18n } from 'vue-i18n';
import InnerLoader from '@/components/InnerLoader.vue';

export default defineComponent({
    name: 'add new dimension',
    components: {
        Input,
        // Select,
        InnerLoader
    },

    setup() {
        const modal: any = ref();
        const loading = ref(false);
        const emitter: any = inject('emitter');
        const current_page = ref(1);
        const editDimensionForm = ref<null | HTMLFormElement>(null);
        const measurements = ref([]) as Record<any, any>;
        const i18n = useI18n();
        const initialForm = ref({});
        const changedFields = ref([]) as Record<any, any>;
        // Given Edit Dimension Form Interface
        const editDimensionData = ref<EditDimensionForm>({
            id: null,
            name: '',
            measurement: ''
        });

        measurements.value = [
            {
                label: 'message.GRAMS',
                value: 'gm'
            },
            {
                label: 'message.CENTIMETER',
                value: 'cm'
            }
        ];
        // Submit handler Request
        const submitHandlerForm = async () => {
            editDimensionForm.value?.validate(async (valid: boolean) => {
                if (valid && Object.entries(changedFields.value).length > 0) {
                    const formData = {
                        id: editDimensionData.value?.id,
                        ...changedFields.value
                    };

                    loading.value = true;
                    await Apollo.mutate({
                        mutation: UPDATE_DIMENSION,
                        variables: { input: formData },
                        update: (store, { data: { update_dimension } }) => {
                            modal.value.hide();
                            loading.value = false;
                            Notify.success(`${i18n.t('message.RECORD_UPDATED_SUCCESSFULLY')}`);
                        }
                    }).catch(() => {
                        loading.value = false;
                    });
                } else {
                    modal.value.hide();
                    loading.value = false;
                    Notify.success(`${i18n.t('message.RECORD_UPDATED_SUCCESSFULLY')}`);
                }
            });
        };

        // Reset Form Data On Model Popup
        const resetForm = () => {
            emitter.emit('clearInput');
            current_page.value = 1;
            editDimensionForm.value?.resetFields();
            changedFields.value = [];
            initialForm.value = [];
        };

        // Emitter To Open Model
        emitter.on('editDimensionAction', (dimension: Record<any, any>) => {
            resetForm();
            current_page.value = dimension.current_page;
            editDimensionData.value.id = dimension.id;
            editDimensionData.value.name = dimension.name;
            editDimensionData.value.measurement = dimension.measurement;
            initialForm.value = { ...editDimensionData.value }; // clone the initial form state

            modal.value = new Modal(document.getElementById('modal_update_dimension')) as HTMLElement;
            modal.value.show();
        });

        const trackChanges = key => {
            if (editDimensionData.value[key] !== initialForm.value[key]) {
                changedFields.value[key] = editDimensionData.value[key];
            } else {
                delete changedFields.value[key];
            }
        };

        return {
            loading,
            editDimensionData,
            editDimensionForm,
            measurements,
            submitHandlerForm,
            trackChanges
        };
    }
});
